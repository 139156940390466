import { type ChainflipNetwork } from '@chainflip/sdk/swap';

export const ZERO_EVM_ADDRESS = '0x0000000000000000000000000000000000000000';

export const SWAPPING_APP_DOMAINS: Record<ChainflipNetwork, string> = {
  mainnet: 'swap.chainflip.io',
  perseverance: 'swap.perseverance.chainflip.io',
  sisyphos: 'swap.sisyphos.staging',
  backspin: 'swap.backspin.staging',
};

export const defaultAnimationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2, ease: 'easeOut' },
};

export const animationPropsWithTranslate = {
  initial: { opacity: 0, translateY: 16 },
  animate: { opacity: 1, translateY: 0 },
  exit: { opacity: 0, translateY: 16 },
  transition: { duration: 0.3, ease: 'easeOut' },
};
