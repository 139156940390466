import { Squid } from '@0xsquid/sdk';
import {
  type StatusResponse as SquidSdkStatusResponse,
  type Token as SquidSdkToken,
  type RouteRequest as SquidSdkRouteRequest,
  type RouteActionResponse as SquidSdkRouteAction,
  ChainType as SquidSdkChainType,
  SlippageMode as SquidSdkSlippageMode,
  ActionType as SquidSdkActionType,
} from '@0xsquid/sdk/dist/types';
import { assert } from '@chainflip/utils/assertion';
import { isTruthy } from '@chainflip/utils/guard';
import { type WalletContextState } from '@solana/wallet-adapter-react';
import { type Connection } from '@solana/web3.js';
import { isAxiosError } from 'axios';
import { chainById, type ChainId } from '@/shared/assets/chains';
import { type Token, tokenByAddress } from '@/shared/assets/tokens';
import { NATIVE_TOKEN_ADDRESS } from '@/shared/assets/tokens/constants';
import { isTestnet } from '@/shared/featureFlags';
import { type WalletClient, clientToSignerEthersV5 } from '@/shared/hooks/useEthersSigner';
import { TokenAmount } from '@/shared/utils';
import { type BaseIntegration, getDeterministicRouteId } from './manager';
import { loadRouteFromLocalStorage, storeTransactionHashInLocalStorage } from './storage';
import { SquidLogo } from '../assets/logo/squid';
import { client } from '../client';
import { ZERO_EVM_ADDRESS } from '../utils/consts';
import {
  type ExecuteSwapResponse,
  type RouteRequest,
  type RouteResponseStep,
  type SquidRouteResponse,
  type SquidStatusResponse,
  type SwapStatus,
} from '.';

const SQUID_NATIVE_TOKEN_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';
const SQUID_DEFAULT_SLIPPAGE = 3; // 3%

const mapSquidChainId = (squidChainType: SquidSdkChainType, squidChainId: string): ChainId =>
  squidChainType === SquidSdkChainType.EVM
    ? `evm-${Number(squidChainId)}`
    : `${squidChainType}-${squidChainId}`;

const mapChainIdToSquid = (chainId: ChainId): string | undefined => {
  if (chainId.startsWith('evm-')) {
    return chainId.replace('evm-', '');
  }
  if (chainId.startsWith('cosmos-')) {
    return chainId.replace('cosmos-', '');
  }
  return undefined;
};

const mapSquidTokenAddress = (squidAddress: string): string =>
  squidAddress === SQUID_NATIVE_TOKEN_ADDRESS ? NATIVE_TOKEN_ADDRESS : squidAddress;

const mapTokenAddressToSquid = (address: string): string =>
  address === NATIVE_TOKEN_ADDRESS ? SQUID_NATIVE_TOKEN_ADDRESS : address;

const mapSquidToken = (squidToken: SquidSdkToken): Token =>
  tokenByAddress(
    mapSquidChainId(squidToken.type, squidToken.chainId),
    mapSquidTokenAddress(squidToken.address),
    squidToken.logoURI,
    {
      name: squidToken.name,
      symbol: squidToken.symbol,
      decimals: squidToken.decimals,
    },
  );

const mapSquidAction = (squidAction: SquidSdkRouteAction): RouteResponseStep | undefined => {
  const srcToken = mapSquidToken(squidAction.fromToken);
  const srcAmount = new TokenAmount(squidAction.fromAmount, srcToken.decimals);
  const destToken = mapSquidToken(squidAction.toToken);
  const destAmount = new TokenAmount(squidAction.toAmount, destToken.decimals);

  if (squidAction.type === SquidSdkActionType.SWAP) {
    return {
      protocolName: squidAction.provider ?? 'Squid Router',
      protocolLink: undefined,
      srcToken,
      srcAmount,
      destToken,
      destAmount,
    };
  }

  if (squidAction.type === SquidSdkActionType.BRIDGE) {
    return {
      protocolName: 'Squid Router',
      protocolLink: undefined,
      srcToken,
      srcAmount,
      destToken,
      destAmount,
    };
  }

  return undefined;
};

const mapSquidStatus = (status: SquidSdkStatusResponse | undefined): SwapStatus => {
  switch (status?.status) {
    case undefined:
      return 'waiting_for_src_tx';
    case 'source_gateway_called':
    case 'express_executed':
    case 'executing':
    case 'destination_gateway_approved':
      return 'waiting_for_dest_tx';
    case 'destination_executed':
      return 'completed';
    case 'error':
      return 'failed';
    case 'error_fetching_status':
    default:
      return 'unknown';
  }
};

export class SquidIntegration implements BaseIntegration {
  private constructor(readonly sdk: Squid) {}

  static async init() {
    const squid = new Squid({
      // https://docs.squidrouter.com/dev-resources/base-urls-and-other-links
      baseUrl: isTestnet
        ? 'https://testnet.v2.api.squidrouter.com'
        : 'https://v2.api.squidrouter.com',
      integratorId: 'chainflip-sdk',
    });
    await squid.init();
    return new SquidIntegration(squid);
  }

  readonly name = 'Squid';

  readonly logo = SquidLogo;

  getChains = async () => {
    const { chains } = this.sdk;

    return chains
      .map((chain) => chainById(mapSquidChainId(chain.chainType, chain.chainId)))
      .filter(isTruthy);
  };

  getDestinationChains = async (srcChainId: ChainId) => {
    const squidChainId = mapChainIdToSquid(srcChainId);
    if (!squidChainId) return [];

    const chains = await this.getChains();
    return chains.filter((chain) => chain.id !== srcChainId);
  };

  getTokens = async (chainId: ChainId): Promise<Token[]> => {
    const { tokens } = this.sdk;
    const squidChainId = mapChainIdToSquid(chainId);

    const chainTokens = tokens.filter((token) => token.chainId === squidChainId);

    return chainTokens.map(mapSquidToken);
  };

  getRoutes = async (routeParams: RouteRequest) => {
    if (routeParams.srcChainId === routeParams.destChainId) return []; // squid does not support same chain swaps (27th July 2023)
    const srcChainId = mapChainIdToSquid(routeParams.srcChainId);
    const destChainId = mapChainIdToSquid(routeParams.destChainId);
    if (!srcChainId || !destChainId) return [];

    const params: SquidSdkRouteRequest = {
      fromChain: srcChainId,
      toChain: destChainId,
      fromToken: mapTokenAddressToSquid(routeParams.srcTokenAddress),
      toToken: mapTokenAddressToSquid(routeParams.destTokenAddress),
      fromAmount: routeParams.amount.toString(),
      fromAddress: ZERO_EVM_ADDRESS, // replaced by address of connected wallet in executeSwap
      toAddress: ZERO_EVM_ADDRESS, // replaced by destination address input value
      slippageConfig: {
        slippage: SQUID_DEFAULT_SLIPPAGE,
        autoMode: SquidSdkSlippageMode.NORMAL,
      },
    };

    let route;
    try {
      ({ route } = await this.sdk.getRoute(params));
    } catch (error) {
      // TODO: handle expected errors from squid without logging an error

      // eslint-disable-next-line no-console
      console.error('failed to load route from squid', error);
      return [];
    }

    const srcToken = mapSquidToken(route.estimate.fromToken);
    const destToken = mapSquidToken(route.estimate.toToken);

    const platformFees = route.estimate.feeCosts.map((fee) => ({
      name: fee.name,
      token: mapSquidToken(fee.token),
      amount: new TokenAmount(fee.amount, fee.token.decimals),
    }));
    const gasFees = route.estimate.gasCosts.map((fee) => ({
      token: mapSquidToken(fee.token),
      amount: new TokenAmount(fee.amount, fee.token.decimals),
    }));
    const steps = route.estimate.actions.map(mapSquidAction).filter(isTruthy);

    const routeResponse = {
      integration: 'squid' as const,
      integrationData: route,
      srcToken,
      srcAmount: new TokenAmount(route.estimate.fromAmount, srcToken.decimals),
      destToken,
      destAmount: new TokenAmount(route.estimate.toAmount, destToken.decimals),
      steps,
      gasFees,
      platformFees,
      durationSeconds: route.estimate.estimatedRouteDuration,
    };

    return [{ ...routeResponse, id: getDeterministicRouteId(routeResponse) }];
  };

  executeEvmSwap = async (swapId: string, walletClient: WalletClient) => {
    const route = loadRouteFromLocalStorage('squid', swapId);
    if (!route || route.integration !== 'squid') {
      throw new Error(`route for swap "${swapId}" not found`);
    }

    // re-request route from squid to make sure squid calldata matches connected wallet
    const signer = clientToSignerEthersV5(walletClient);
    const squidRoute = await this.sdk.getRoute({
      ...route.integrationData.params,
      fromAddress: await signer.getAddress(),
      toAddress: route.destAddress,
    });
    route.integrationData = squidRoute.route;

    const response: ExecuteSwapResponse = {
      integration: 'squid' as const,
      integrationData: undefined,
      error: undefined,
    };

    const sdkResponse = await this.sdk.executeRoute({
      route: route.integrationData,
      signer,
    });
    assert('hash' in sdkResponse, 'missing hash in squid sdk response');
    response.integrationData = sdkResponse.hash;
    storeTransactionHashInLocalStorage('squid', swapId, sdkResponse.hash);

    try {
      await client.post('third-party-swap', {
        uuid: swapId,
        routeResponse: route,
        txHash: sdkResponse.hash,
        txLink: '',
      });
    } catch (err) {
      response.error = JSON.stringify(err);
    }

    return response;
  };

  getStatus = async (swapId: string): Promise<SquidStatusResponse | undefined> => {
    let route = loadRouteFromLocalStorage('squid', swapId);

    try {
      if (!route) {
        const response = await client.get<{
          routeResponse: SquidRouteResponse;
          txHash: string;
        }>(`third-party-swap/${swapId}`);
        route = {
          ...response.data.routeResponse,
          destAddress: response.data.routeResponse.integrationData.params.toAddress as string,
          transactionHash: response.data.txHash,
        };
      }
    } catch (e) {
      // ignore: swap is only stored after tx execution
      if (!(isAxiosError(e) && e.response?.status === 404)) throw e;
    }

    if (!route || route.integration !== 'squid') {
      return undefined;
    }

    let squidStatus: SquidSdkStatusResponse | undefined;
    if (route.transactionHash) {
      try {
        squidStatus = await this.sdk.getStatus({
          transactionId: route.transactionHash,
        });
      } catch (e) {
        // ignore: tx is not immediately found
        if (!(isAxiosError(e) && e.response?.status === 404)) throw e;
      }
    }

    return {
      id: swapId,
      shareableId: squidStatus ? swapId : undefined,
      integration: 'squid' as const,
      integrationData: squidStatus,
      route,
      status: mapSquidStatus(squidStatus),
      depositAddress: undefined,
      srcTransactionHash: route.transactionHash,
      srcConfirmationCount: undefined,
      destAddress: route.integrationData.params.toAddress,
      destTransactionHash: squidStatus?.toChain?.transactionId,
      duration: squidStatus?.timeSpent?.total,
    };
  };

  // eslint-disable-next-line class-methods-use-this
  executeSolanaSwap(
    _swapId: string,
    _wallet: WalletContextState,
    _connection: Connection,
  ): Promise<ExecuteSwapResponse> {
    throw new Error('Method not implemented.');
  }
}
